import axios from 'axios';
import { getTokenDeploymentInfoEndpoint, spawnProcessEndpoint, tokenDeployment, getBalanceEndpoint } from '../constants';
import {generateHmacSignature}  from '../utilities/index';
import { toast } from 'react-toastify';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_API_BASE_URL}`
const getTokenDeployementInfoUrl = `${baseUrl}${getTokenDeploymentInfoEndpoint}`
const spawnProcessUrl = `${baseUrl}${spawnProcessEndpoint}`
const deployTokenUrl = `${baseUrl}${tokenDeployment}`
const getBalanceUrl = `${baseUrl}${getBalanceEndpoint}`

export const deployToken = async (payload) => {
  const signature = generateHmacSignature(payload)

  try {
    const response = await axios.post(deployTokenUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'x-signature': signature
      }
    });
    toast.success('Token Deployed successfully!')
    return response.data;
  } catch (error) {
    console.log(error?.response?.data?.message);
  }
};

export const spawnProcess = async (walletAddress) => {
  const payload = {walletAddress}
  const signature = generateHmacSignature(payload)

  try {
    const response = await axios.post(spawnProcessUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'x-signature': signature
      }
    });
    toast.success('Process spawned successfully!')
    return response?.data?.processId;
  } catch (error) {
    console.log(error?.response?.data?.message);
  }
};

export const getTokenDeployementInfo = async (walletAddress) => {
  try {
    const response = await axios.get(`${getTokenDeployementInfoUrl}${walletAddress}`);
    return response?.data;
  } catch (error) {
    console.log(error?.response?.data?.message);
  }
};

export const getBalance = async (walletAddress, processId) => {
  try {
    const response = await axios.get(`${getBalanceUrl}${walletAddress}/${processId}`);
    return response?.data;
  } catch (error) {
    console.log(error?.response?.data?.message)
  }
};