import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useActiveAddress } from 'arweave-wallet-kit'


const ProtectedForm = () => {
    const address = useActiveAddress()

    if (!address){
        return <Navigate to="/" />
    }

    return (
        <Outlet/>
    );
};

export default ProtectedForm;
