import React from 'react'
import "../App.css"

const WalletAddress = ({ children }) => {
    return (
        <div className='WalletAddress'>
            <p className='Heading'>Wallet Address</p>
            <div>{children}</div>
        </div>
    )
}

export default WalletAddress
