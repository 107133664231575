// Endpoints

// Get method
export const getTokenDeploymentInfoEndpoint ='/'

// Get method
export const spawnProcessEndpoint='/process'

// Get method
export const getBalanceEndpoint='/balance/'

// post method
export const tokenDeployment='/'