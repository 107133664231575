import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Form from "../Components/Form";
import { getBalance, getTokenDeployementInfo, spawnProcess } from "../Services";
import TokenInfo from "../Components/TokenInfo";
import { useActiveAddress } from 'arweave-wallet-kit'
import { parseTokenInfo } from "../utilities";

const Dashboard = () => {
  const [loadingTokenDeployment, setLoadingTokenDeployment] = useState(false);
  const [loadingSpawningProcess, setLoadingSpawningProcess] = useState(false);
  const [isDeployed, setIsDeployed] = useState(false);
  const address = useActiveAddress()
  const [tokenInfo, setTokenInfo] = useState([]); 
  const [walletBalance, setWalletBalance] = useState({});
  // localStorage.getItem("tokenInfo");

  const updateTokenDeploymentLoading = (state) => {
    setLoadingTokenDeployment(state);
  };

  useEffect(() => {
    const fetchGetTokenDeployementInfo = async () => {
      setLoadingTokenDeployment(true);
      try {
        const result = await getTokenDeployementInfo(address);
        const _isDeployed = result?.isDeployed;
        const info = result?.tokenInfo;
        setIsDeployed(_isDeployed);
        setTokenInfo(parseTokenInfo(info || []));
        setLoadingTokenDeployment(false);
        if (!_isDeployed) {
          const spawnTheProcess = async () => {
            setLoadingSpawningProcess(true);
            try {
              await spawnProcess(address);
              setLoadingSpawningProcess(false);
            } catch (error) {
              console.error("Error spawning the process: ", error);
            } finally {
              setLoadingSpawningProcess(false);
            }
          };
          await spawnTheProcess();
        }
      } catch (error) {
        setLoadingTokenDeployment(false);
        console.error("Error fetching token deployment status: ", error);
      } finally {
        setLoadingTokenDeployment(false);
      }
    };

    fetchGetTokenDeployementInfo();
  }, [address]);

  useEffect(() => {
    if (tokenInfo !== undefined && tokenInfo?.length > 0) {
      try{
        // const parsedTokenInfo = parseTokenInfo(tokenInfo);
        const tokenAddress = tokenInfo.filter(
          (tag) => tag["name"] === "Token Address"
        );
        const fetchBalanceInfo = async () => {
          try {
            const balance = await getBalance(address, tokenAddress[0]["value"]);
            setWalletBalance(balance);
          } catch (error) {
            console.log("error: ", error);
          }
        };

        fetchBalanceInfo();
      }catch(err) {console.log('error in token info useEffect', err)}
    }
  }, [tokenInfo, address]);


  return (
    <div className="Dashboard">
      <Header loading={loadingTokenDeployment || loadingSpawningProcess} />
      {loadingSpawningProcess || loadingTokenDeployment ? (
        <div style={{ margin: "auto" }}>
          <div className="loader"></div>
        </div>
      ) : isDeployed ? (
        <div style={{ margin: "auto" }}>
          {tokenInfo!==undefined && tokenInfo?.length > 0 && (
            <div>
              {Object.keys(walletBalance).length > 0 && (
                <div className="walletBalance">
                  <p>Balance:</p> <p>{walletBalance["value"]}</p>
                </div>
              )}
              <div className="deployedTokenText">
                <TokenInfo data={tokenInfo} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Form
          setIsDeployed={setIsDeployed}
          setTokenInfo={setTokenInfo}
          loadingTokenDeployment={loadingTokenDeployment}
          updateTokenDeploymentLoading={updateTokenDeploymentLoading}
        />
      )}
    </div>
  );
};

export default Dashboard;
