import React from 'react'

const TokenInfo = ({data}) => {
    const tags = data || [];
    const cellStyle = {
        padding: '10px' // Adjust the padding value as needed
    };

    return (
        <div>
        <table border="1">
            <thead>
                <tr>
                    <th style={cellStyle}>Name</th>
                    <th style={cellStyle}>Value</th>
                </tr>
            </thead>
            <tbody>
                {tags?.length && tags.map((item, index) => (
                    <tr key={index}>
                        <td style={cellStyle}>{item.name}</td>
                        <td style={cellStyle}>{item.value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    )
}

export default TokenInfo
