import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useActiveAddress } from 'arweave-wallet-kit'


const ProtectedHome = () => {
    const address = useActiveAddress()

    if (address){
        return <Navigate to="/dashboard" /> 
    }

    return (
        <Outlet/> 
    );
};

export default ProtectedHome;
