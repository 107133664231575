import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedForm from '../Hoc/ProtectedForm';
import Home from '../Pages/Home';
import ProtectedHome from '../Hoc/ProtectedHome';
import Dashboard from '../Pages/Dashboard';

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<ProtectedHome />}>
                <Route exact path="/" element={<Home />} />
            </Route>
            <Route element={<ProtectedForm />}>
                <Route path="/dashboard" element={<Dashboard/>} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
