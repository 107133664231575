import React from 'react';

const InputField = ({ name, type, maxLength, placeholder, value, onChange, error }) => {
  const handleKeyDown = (event) => {
    const name = event.target.name
    if((name === 'denomination' || name === 'supply') && (event.key === '.' || event.key === '-')){
      event.preventDefault()
    }
  }
  return (
    <div className="input-field">
      <input
        className={error ? 'error' : ''}
        disabled={name === 'walletAddress' ? true : false}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        onKeyDown={(event) => handleKeyDown(event)}
      />
    </div>
  );
};

export default InputField;
