import CryptoJS from 'crypto-js'

export const generateHmacSignature = (data) => {
    const hmac = CryptoJS.HmacSHA256(JSON.stringify(data), process.env.REACT_APP_HMAC_SECRET);
    return hmac.toString(CryptoJS.enc.Hex);
  };

export const parseTokenInfo = (tokenInfo) => {
  const tags = tokenInfo?.[0]?.Tags;
  const updatedTags = tags
            ?.filter(
              (tag) => tag["name"] !== "From-Module" && tag["name"] !== "Ref_"
            )
            .map((tag) => {
              if (tag["name"] === "From-Process") {
                return { name: "Token Address", value: tag["value"] };
              }

              return tag;
            });
  return updatedTags;
}