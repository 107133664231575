import React, { useEffect, useRef } from 'react';
import { ConnectButton, useActiveAddress } from 'arweave-wallet-kit';
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const address = useActiveAddress();
  const canvasRef = useRef(null);

  useEffect(() => {
    if (address) {
      navigate('/dashboard');
    }
  }, [address, navigate]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particlesArray = [];
    const numberOfParticles = 100;
    const maxDistance = 100;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initParticles();
    });

    class Particle {
      constructor(x, y, size, speedX, speedY) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.speedX = speedX;
        this.speedY = speedY;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x > canvas.width || this.x < 0) {
          this.speedX = -this.speedX;
        }
        if (this.y > canvas.height || this.y < 0) {
          this.speedY = -this.speedY;
        }
      }

      draw() {
        ctx.fillStyle = '#000';  // Crna boja čestica
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }
    }

    function initParticles() {
      particlesArray = [];
      for (let i = 0; i < numberOfParticles; i++) {
        const size = Math.random() * 5 + 1;
        const x = Math.random() * (canvas.width - size * 2) + size;
        const y = Math.random() * (canvas.height - size * 2) + size;
        const speedX = (Math.random() * 2) - 1;
        const speedY = (Math.random() * 2) - 1;
        particlesArray.push(new Particle(x, y, size, speedX, speedY));
      }
    }

    function connectParticles() {
      for (let a = 0; a < particlesArray.length; a++) {
        for (let b = a; b < particlesArray.length; b++) {
          const dx = particlesArray[a].x - particlesArray[b].x;
          const dy = particlesArray[a].y - particlesArray[b].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxDistance) {
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';  // Crna linija, prozirnost 0.1
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
            ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
            ctx.stroke();
          }
        }
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < particlesArray.length; i++) {
        particlesArray[i].update();
        particlesArray[i].draw();
      }
      connectParticles();
      requestAnimationFrame(animate);
    }

    initParticles();
    animate();

    return () => {
      window.removeEventListener('resize', initParticles);
    };
  }, []);

  return (
    <div style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></canvas>

      <h1 style={{ 
        fontSize: '2.5em', 
        color: '#000000', 
        textAlign: 'center', 
        position: 'relative', 
        zIndex: 1,  // Osigurava da naslov bude iznad čestica
      }}>
        Create Your Token on AO
      </h1>

      <div style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'relative', 
        zIndex: 1,  // Osigurava da gumb bude iznad čestica
      }}>
        <ConnectButton
          accent="#ffffff"
          style={{
            border: '1px solid #000000',
            backgroundColor: '#ffffff',
            color: '#000000',
            padding: '10px 20px',
            fontSize: '1em',
            cursor: 'pointer',
            borderRadius: '5px',
          }}
          profileModal={true}
          showBalance={true}
          showProfilePicture={true}
        />
      </div>
    </div>
  );
};

export default Home;
