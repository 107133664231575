import React, { useEffect, useState } from "react";
import { inputFields } from "../Data";
import InputField from "./InputField";
import "../App.css";
import { deployToken } from "../Services";
import Button from "./Button";
import InputFieldError from "./InputFieldError";
import { useActiveAddress } from "arweave-wallet-kit";
import { parseTokenInfo } from "../utilities";

const Form = ({
  setIsDeployed,
  setTokenInfo,
  loadingTokenDeployment,
  updateTokenDeploymentLoading,
}) => {
  const address = useActiveAddress();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // Initialize formData with inputFields
    const initialData = inputFields.reduce((acc, field) => {
      acc[field.name] = "";
      return acc;
    }, {});

    // Set wallet address if available
    if (address) {
      const walletField = inputFields.find(
        (field) => field.name === "walletAddress"
      );
      if (walletField) {
        initialData[walletField.name] = address;
      }
    }

    setFormData(initialData);
  }, [address]);

  // Handle change event
  const handleChange = (name, event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData["name"]) {
      errors["name"] = "name is required!!";
    } else {
      delete errors["name"];
    }

    if (!formData["denomination"]) {
      errors["denomination"] = "denomination is required!!";
    } else {
      delete errors["denomination"];
    }

    if (!formData["ticker"]) {
      errors["ticker"] = "ticker is required!!";
    } else {
      delete errors["ticker"];
    }

    if (!formData["supply"]) {
      errors["supply"] = "supply is required!!";
    } else {
      delete errors["supply"];
    }

    if (!formData["walletAddress"]) {
      errors["walletAddress"] = "walletaddress is required!!";
    } else if (typeof formData["walletAddress"] !== "string") {
      errors["walletAddress"] = "walletaddress must be of type number!!";
    } else {
      delete errors["walletAddress"];
    }

    return errors;
  };

  const generatePayload = (data) => {
    if (data && typeof data === "object") {
      return {
        ...data,
        supply: Number(data.supply),
        denomination: Number(data.denomination),
      };
    } else {
      throw new Error("Invalid input data");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setFormErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      e.preventDefault();
      updateTokenDeploymentLoading(true);

      const payload = generatePayload(formData);
      try {
        const response = await deployToken(payload);
        const tags = response?.tokenInfo?.[0]?.Tags;
        if (tags !== undefined && tags?.length > 0) {
          setTokenInfo(parseTokenInfo(response?.tokenInfo));
          updateTokenDeploymentLoading(false);
          setIsDeployed(true);
        }
      } catch (error) {
        updateTokenDeploymentLoading(false);
        console.log("Failed to deploy token: ", error);
      }
    }
  };

  return (
    <div className="LaunchToken">
      <h2>Launch Token</h2>

      <div className="form">
        {inputFields.map((field) => (
          <div className="InputFieldBox">
            <div className="InputFieldHeading">
              <label className="InputFieldLabel" for={field.name}>
                {field.label}
              </label>
              {formErrors[field.name] && (
                <InputFieldError>{formErrors[field.name]}</InputFieldError>
              )}
            </div>
            <InputField
              key={field.id}
              name={field.name}
              type={field.type}
              maxLength={field.maxLength}
              placeholder={field.placeholder}
              value={formData[field.name]}
              onChange={(event) => handleChange(field.name, event)}
              error={formErrors[field.name]}
            />
          </div>
        ))}
      </div>

      <div>
        <Button onClick={handleSubmit} disabled={loadingTokenDeployment}>
          {loadingTokenDeployment ? "Submitting..." : "Submit Form"}
        </Button>
      </div>
    </div>
  );
};

export default Form;
