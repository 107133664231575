import React from "react";
import WalletAddress from "./WalletAddress";
import "../App.css";
import { useConnection } from "arweave-wallet-kit";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useActiveAddress } from "arweave-wallet-kit";
import logo from "../assets/logo.png";

const Header = ({ loading, isDisabledDisconnect }) => {
  const { disconnect } = useConnection();
  const navigate = useNavigate();
  const address = useActiveAddress();
  const handleDisconnect = async () => {
    try {
      await disconnect();
      navigate("/");
    } catch (error) {
      console.error("Error disconnecting:", error);
    }
  };
  return (
    <div className="Header">
      <div className="navbar">
        <div className="LogoTitle">
          <img className="logo" src={logo} alt="logo" />
          <p>Create token on AO</p>
        </div>
        <Button
          onClick={handleDisconnect}
          disabled={loading || isDisabledDisconnect}
        >
          Disconnect Wallet
        </Button>
      </div>

      <div className="WalletAddressHeading">
        <WalletAddress>{address}</WalletAddress>
      </div>
    </div>
  );
};

export default Header;
