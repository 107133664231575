import React from 'react'

const InputFieldError = ({children}) => {
  return (
    <div className='InputFieldError'>
      {children}
    </div>
  )
}

export default InputFieldError
